<template>
    <LiefengContent>
        <template #title>{{ "机构社区行为数据统计" }}</template>
        <template #toolsbarRight>
            <Form class="search" :inline="true">
              <FormItem >
                <Select transfer style="text-align:left;" v-model="dimensionld" placeholder="请选择组织维度"  @on-change="selectDimensionld" >
                  <Option :value="item.id" v-for="(item,index) in codeOptionsTwo" :key="index">{{item.name}}</Option>
                </Select>
              </FormItem>
              <FormItem>
                  <DatePicker
                    :options="dateOption"
                    :transfer="true"
                    v-model="time"
                    format="yyyy-MM-dd" 
                    type="daterange" 
                    placeholder="请选择开始时间和结束时间"
                ></DatePicker>
              </FormItem>
            </Form>
            <Button style="margin: 0 4px" type="primary" icon="ios-search" @click="getList">查询</Button>
            <Button style="margin: 0 4px" type="success" icon="ios-refresh" @click="restBtn">重置</Button>
            <Button type="error" style="margin: 0 0 0 50px" @click="Export">导出报表</Button>
        </template>
        <template #contentArea>
            <div style="display: flex; justify-content: space-between">
                <span style="margin:20px 20px 20px auto;">更新时间：{{ nowTime1 + " 00:00:00" || "" }}</span>
            </div>
            <LiefengTable
                ref="tab"
                :tableData="tableData"
                :talbeColumns="talbeColumns"
                :loading="loading"
                :fixTable="true"
                :hidePage="isHidePage"
                :curPage="page"
                :total="total"
                :page-size="pageSize"
                :pagesizeOpts="[50, 1000 , 2000 ,3000]"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengCascaderCity from "./children/LiefengCascaderCity"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        LiefengCascaderCity,
    },
    data() {
        return {
            orgOnlineStatus: "", // 切换的状态
            level: 3, // 切换的级别
            columnName: 0, // 栏目数
            nowTime1: "", // 当前时间
            resetNum: 1, // 是否清空当前社区选择

            tableData: [],
            talbeColumns: [],
            loading: false,
            menuCodeObj: {},

            cascaderList: [],

            // 栏目点击参数

            blankStatus: false,
            bTableData: [],
            bTalbeColumns: [
                {
                    title: "序号",
                    key: "num",
                    align: "center",
                    width: 80,
                },
                {
                    title: "栏目路径",
                    key: "columnName",
                    minWidth: 400,
                    align: "center",
                },
                {
                    title: "栏目编码",
                    key: "columnCode",
                    minWidth: 80,
                    align: "center",
                },
            ],
            bLoading: false,
            nameUrl: "",
            clumnNum: 0,
            nowTime2: "",

            onlineType: "",

            disabled: false, // 是否禁用选择器
            isHidePage: true,

            page: 1,
            pageSize: 50,
            total: 0,

            allTableData: [], //暂存全部信息
            showCascader: true,

            time:'',
            codeOptionsTwo:[],
            dimensionld:''
        }
    },
    async created() {
        this.nowTime1 = this.$core.formatDate(new Date(new Date().getTime()), "yyyy-MM-dd")
        await this.getList()
        await this.getCode()
    },
    methods: {
        // 选择组织机构
        selectDimensionld(val){
            this.dimensionld = val
        },
        // 重置按钮
        restBtn(){
            this.time = []
            this.dimensionld = ''
            this.getList()
        },
        // 改变分页
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.tableData = []
            if(val.page == 1){
                this.tableData = JSON.parse(JSON.stringify(this.allTableData)).slice(0, this.page * this.pageSize)
            }else{
                this.tableData = JSON.parse(JSON.stringify(this.allTableData)).slice((this.page * this.pageSize) - (this.pageSize), this.page * this.pageSize)
            }
        },
        // 改变示范社区东西
        changeSelect(val) {
            if (val == undefined) return
            this.getList()
        },
        // 改变选择社区组件
        changeCasader(val) {
            this.cascaderList = val
            this.onlineType = ""
            if (!val.length) {
                this.resetNum++
                this.getList()
            }
            if (this.level == "3") {
                if (val.length == 1) this.getList()
            } else if (this.level == "4") {
                if (val.length == 2) this.getList()
            } else if (this.level == "5") {
                if (val.length == 3) this.getList()
            }
        },
        async checkStatus(val) {
            if (this.orgOnlineStatus == val) return
            this.orgOnlineStatus = val
            await this.getList()
        },
        async changeLevel(val) {
            if (this.level == val) {
                return
            }
            this.level = val
            this.showCascader = false
            this.$nextTick(() => {
                this.showCascader = true
            })
            this.isHidePage = true
            if (this.level == "5") this.isHidePage = false
            this.resetNum++
            this.cascaderList = []
            await this.getList()
        },

        async changeLevel2(val) {
            if (this.level == val) {
                return
            }
            this.level = val
            this.showCascader = false
            this.$nextTick(() => {
                this.showCascader = true
            })
            this.isHidePage = true
            if (this.level == "5") this.isHidePage = false
            this.resetNum++
            await this.getList()
        },

        //获取列表
        async getList(val) {
            let arr1 = []
            let arr2 = []
            this.disabled = true

            if (this.level == 3) {
                arr1 = [
                    {
                        title: "区",
                        key: "orgName",
                        align: "center",
                        width: 200
                    },
                ]
            } else if (this.level == 4) {
                arr1 = [
                    {
                        title: "区",
                        key: "zoneName",
                        align: "center",
                        width: 200,
                    },
                    {
                        title: "街道",
                        key: "orgName",
                        align: "center",
                        width: 200
                    },
                ]
            } else if (this.level == 5) {
                arr1 = [
                    {
                        title: "区",
                        key: "zoneName",
                        align: "center",
                        width: 200,
                    },
                    {
                        title: "街道",
                        key: "streetName",
                        align: "center",
                        width: 200,
                    },
                    {
                        title: "社区",
                        key: "orgName",
                        align: "center",
                        width: 200,
                    },
                ]
            }
            arr2 = [
                {
                    title: "访问次数",
                    key: "uv",
                    align: "center",
                    minWidth: 100,
                    render: (h, params) => {
                        return h("div", {}, params.row.uv ? params.row.uv : 0)
                    },
                },
                {
                    title: "访问人数",
                    key: "uvUser",
                    align: "center",
                    minWidth: 100,
                    render: (h, params) => {
                        return h("div", {}, params.row.uvUser ? params.row.uvUser : 0)
                    },
                },
                {
                    title: "参与次数",
                    key: "pv",
                    align: "center",
                    minWidth: 100,
                    render: (h, params) => {
                        return h("div", {}, params.row.pv ? params.row.pv : 0)
                    },
                },
                {
                    title: "参与人数",
                    key: "pvUser",
                    align: "center",
                    minWidth: 100,
                    render: (h, params) => {
                        return h("div", {}, params.row.pvUser ? params.row.pvUser : 0)
                    },
                },
                // {
                //     title: "收藏次数",
                //     key: "fav",
                //     align: "center",
                //     minWidth: 100,
                //     render: (h, params) => {
                //         return h("div", {}, params.row.fav ? params.row.fav : 0)
                //     },
                // },
                // {
                //     title: "收藏人数",
                //     key: "favUser",
                //     align: "center",
                //     minWidth: 100,
                //     render: (h, params) => {
                //         return h("div", {}, params.row.favUser ? params.row.favUser : 0)
                //     },
                // },
                {
                    title: "分享次数",
                    key: "share",
                    align: "center",
                    minWidth: 100,
                    render: (h, params) => {
                        return h("div", {}, params.row.share ? params.row.share : 0)
                    },
                },
                {
                    title: "分享人数",
                    key: "shareUser",
                    align: "center",
                    minWidth: 100,
                    render: (h, params) => {
                        return h("div", {}, params.row.shareUser ? params.row.shareUser : 0)
                    },
                },
                {
                    title: "点赞次数",
                    key: "like",
                    align: "center",
                    minWidth: 100,
                    render: (h, params) => {
                        return h("div", {}, params.row.like ? params.row.like : 0)
                    },
                },
                {
                    title: "点赞人数",
                    key: "likeUser",
                    align: "center",
                    minWidth: 100,
                    render: (h, params) => {
                        return h("div", {}, params.row.likeUser ? params.row.likeUser : 0)
                    },
                },
            ]
            this.talbeColumns = [...arr1, ...arr2]
            this.disabled = false
            this.loading = true
            let res = await this.$get("/gateway/api/bi/manage/userAction/selectOrgPublishView", {
                startDate:this.time[0] && this.time[0] != '' ?this.$core.formatDate(this.time[0],'yyyy-MM-dd') :'' ,
                endDate:this.time[1] && this.time[1] != '' ? this.$core.formatDate(this.time[1],'yyyy-MM-dd') : '',
                dimensionld:this.dimensionld
            })
            if (res.code == 200) {
                this.loading = false
                this.tableData = res.dataList
                this.columnName = 0
                this.tableData.map(item => {
                    item.favUser = item.favUser || 0
                    item.likeUser = item.likeUser || 0
                    item.pvUser = item.pvUser || 0
                    item.shareUser = item.shareUser || 0
                    item.uvUser = item.uvUser || 0
                })
                this.allTableData = this.tableData
                if (this.level == 5) {
                    this.total = this.tableData.length
                    this.tableData = JSON.parse(JSON.stringify(this.allTableData)).splice(0, 50)
                }
            } else {
                this.loading = false
                this.$Message.error({
                    content: res.decs,
                    background: true,
                })
            }
        },
        // 获取columnCode
        async getByMenuCode() {
            await this.$get("/old/api/pc/menu/selectMenuByMenuCode", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                menuCode: this.$core.getUrlParam("menuId"),
            }).then(res => {
                if (res.code == 200 && res.data) {
                    this.menuCodeObj = res.data
                }
            })
        },
        //导出报表
        Export() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "是否导出区、街、社区行为数据统计",
                onOk: () => {
                    this.$refs.tab.$refs.lftable.exportCsv({
                        filename: "区、街、社区行为数据统计",
                    })
                },
            })
        },
        changeBlankStatus(val) {
            this.blankStatus = val
        },

        // 维度列表
        async getCode(){
            await this.$get('/orgzz/pc/dimension/selectAuthorizationDimension',{
                     appCode:''
            },{"Content-Type": "application/json"}).then(res=>{
                this.codeOptionsTwo = [{id:'全部',code:'',name:'全部'}]
                if(res.code == 200){
                    res.dataList.map(item =>{
                        if(item){
                            this.codeOptionsTwo.push(item)
                        }
                    })
                }
                })
            },
        },
}
</script>
    
<style scoped lang='less'>
/deep/#title {
    justify-content: space-between;
    display: flex;
    padding: 10px;
}
.readnum {
    display: inline;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 700;
    li {
        display: inline;
        margin-left: 15px;
    }
    .click {
        text-decoration: underline;
        cursor: pointer;
    }
}
.readNum {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 20px;
    li {
        display: inline;
        margin-left: 15px;
    }
}
.ivu-form-item {
    margin-bottom: 5px;
    margin-right: 0px;
}
/deep/.ivu-table-sort {
    width: 26px;
    height: 13px;
    i {
        font-size: 27px;
    }
}
</style>